/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Findaway from '../svg/findaway.svg';
import VisuallyHidden from '@reach/visually-hidden';

import { FaTwitter, FaFacebookSquare, FaYoutube, FaInstagram } from 'react-icons/fa';

import '../scss/styles.scss';

const Layout = ({ children }) => {
    return (
        <div className="site">
            <main>{children}</main>
            <footer className="py-8">
                <div className="container">
                    <a className="no-decoration" href="https://playaway.com">
                        Created by Playaway
                    </a>
                    <ul className="list-flex-inline social-links no-gap">
                        <li>
                            <a href="https://www.facebook.com/search/top?q=playaway">
                                <VisuallyHidden>Facebook</VisuallyHidden>
                                <FaFacebookSquare />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/playawayproducts/">
                                <VisuallyHidden>Instagram</VisuallyHidden>
                                <FaInstagram />
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
